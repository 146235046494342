<script>
import InfoCardForm from "@/views/menu/info-card-page/info-card/infoCardForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    infoCardPageService: "infoCardPageService",
    infoBoxService: "infoBoxService",
    toastService: "toastService"
  }
})
export default class InfoCardDetail extends mixins(InfoCardForm) {
  formId = "detail-info-card-form";

  get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.infoCards_detail;
  }

  async afterCreate () {
    this.infoCardPage = await this.infoCardPageService.read(this.$route.params.idInfoCardPage);
    this.model = await this.infoCardPageService.readCard(this.$route.params.idInfoCardPage, this.$route.params.id);

    if (this.model.idInfoBox !== null) {
      this.model.infoBoxData = await this.infoBoxService.read(this.model.idInfoBox);
      this.model.infoBoxData.enableInfoBox = this.model.infoBoxData.isEnabled;
    } else {
      this.model.infoBoxData = {
        enableInfoBox: false,
        code: "",
        width: null,
        height: null,
        x: null,
        y: null,
        localizations: [],
      };
    }

    this.isReady = true;
  }
}
</script>
